export default {
  data() {
    return {
      isRutValid: true,
    };
  },
  methods: {
    validateRutFromInput(rut) {
      this.isRutValid = false;
      if (rut) {
        rut = rut.replaceAll(".", "");
      }
      if (rut.length > 7) {
        let rutAll = "";
        rutAll = rut.replace("‐", "-");
        if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutAll)) {
          return (this.isRutValid = false);
        } else {
          let tmp = rutAll.split("-");
          let digitShow = tmp[1];
          let rutCalculate = tmp[0];
          if (digitShow == "K") digitShow = "k";
          let S;
          S = this.calculateRut(rutCalculate);
          if (S == digitShow) {
            this.isRutValid = true;
          }
        }
      }
    },
    calculateRut(T) {
      let M = 0,
        S = 1;
      for (; T; T = Math.floor(T / 10))
        S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
      return S ? S - 1 : "k";
    },
    formatRut(rut) {
      rut = rut.replace(/[^0-9kK]+/g, "").toLowerCase();
      if (rut.length > 1) {
        rut = rut.slice(0, -1) + "-" + rut.slice(-1);
        if (rut.length > 5) {
          if (rut.length > 8) {
            return (
              rut.slice(0, -8) + "." + rut.slice(-8, -5) + "." + rut.slice(-5)
            );
          }
          return rut.slice(0, -5) + "." + rut.slice(-5);
        }
      }
      return rut;
    },
  },
};
