<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="mt-4" novalidate @submit.prevent="handleSubmit(onSubmit)">
      <!-- RUT -->
      <ValidationProvider
        vid="rut"
        name="RUT"
        rules="required"
        v-slot="{ errors }"
      >
        <div class="form-group">
          <label for="rut">RUT</label>
          <input
            type="text"
            :class="
              'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')
            "
            id="rut"
            name="rut"
            v-model="user.rut"
            @input="validateRutFromInput(formatRut(user.rut))"
            placeholder="Ingrese su RUT"
            v-rut
            required
          />
          <div class="text-danger" v-if="!isRutValid && user.rut">
            <small>Por favor ingrese un RUT válido</small>
          </div>

          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <!-- PASSWORD -->
      <ValidationProvider
        vid="contraseña"
        name="Contraseña"
        rules="required"
        v-slot="{ errors }"
      >
        <div class="form-group">
          <label for="passwordInput">Contraseña</label>
          <router-link to="/auth/password-reset1" class="float-right">
            ¿Olvidaste tu contraseña?
          </router-link>
          <input
            type="password"
            :class="
              'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')
            "
            id="passwordInput"
            v-model="user.password"
            placeholder="Contraseña"
            required
          />
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <div class="d-inline-block w-100">
        <div
          class="custom-control custom-checkbox d-inline-block mt-2 pt-1"
        ></div>
        <button
          type="submit"
          :disabled="loading"
          class="btn btn-primary float-right"
        >
          <span v-if="loading">
            <b-spinner small type="grow"></b-spinner>
            Cargando...
          </span>
          <span v-else>Iniciar Sesión</span>
        </button>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import validateRut from "@/Mixins/validateRut";
import { generateDeviceId } from "@/Utils/common";
export default {
  name: "SignInForm",
  mixins: [validateRut],
  mounted() {
    if (process.env.NODE_ENV == "development") {
      this.user = {
        password: "buendia",
        rut: "12.161.538-K",
      };
    }
  },
  data() {
    return {
      user: {
        password: "",
        rut: null,
      },
    };
  },
  methods: {
    ...mapActions({
      login: "login",
    }),
    async onSubmit() {
      if (this.isRutValid) {
        try {
          const response = await this.login({
            ...this.user,
            rut: this.formatRut(this.user.rut),
            device_id: generateDeviceId(),
          });
          if (response?.status == 201) {
            this.$router.push("/");
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      loading: "isLoading",
    }),
  },
};
</script>
