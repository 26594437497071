<template>
  <div>
    <h1 class="mb-0">Inicio de Sesión</h1>
    <p>Ingrese su RUT y contraseña para acceder.</p>
    <sign-in-form></sign-in-form>
  </div>
</template>

<script>
import SignInForm from "./Components/SingInForm.vue";

export default {
  name: "SignIn",
  components: { SignInForm },
  data() {
    return {};
  },
};
</script>
